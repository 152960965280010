var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass: "white--text py-0 my-0",
      attrs: { color: "primary", height: "56px", app: "" },
    },
    [
      _c("div", { staticClass: "d-flex justify-space-between align-center" }, [
        _c("div", { staticClass: "white--text" }, [
          _c("div", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }