<template>
  <v-app style="background-color: #fafafa">
    <v-main fluid tag="section" class="pt-0">
      <PlainTopBar title="Captures" ref="topBar" />
      <section class="pt-16">
        <div class="d-flex justify-center">
          <div>
            <p class="caption mb-2" ref="title">
              <v-icon size="16px" class="mb-1"> {{ mdiCameraIris }} </v-icon>
              Captures History
            </p>
            <v-card>
              <v-card-text class="pa-0">
                <v-data-table
                  :headers="headers"
                  :items="captures"
                  item-key="file_id"
                  class="pa-0 ma-0 cursor-pointer"
                  @click:row="openCaptureLink"
                  fixed-header
                  :height="tableHeight"
                >
                  <template v-slot:[`item.created_on`]="{ item }">
                    {{ formatDateTime(item) }}
                  </template>
                  <template v-slot:[`item.file_extension`]="{ item }">
                    {{ formatType(item) }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      icon
                      color="secondary"
                      @click.stop="copyCaptureLink(item)"
                    >
                      <v-icon>{{ mdiContentCopy }}</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="secondary"
                      @click.stop="openCaptureLink(item)"
                    >
                      <v-icon>{{ mdiOpenInNew }}</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </section>
    </v-main>
  </v-app>
</template>

<script>
import { mdiContentCopy, mdiOpenInNew, mdiCameraIris } from "@mdi/js";
import PlainTopBar from "@/components/app/PlainTopBar";
import axios from "axios";
import moment from "moment";
import { mapMutations } from "vuex";

const APIURL = process.env.VUE_APP_API_URL;
const FRONT_END_URL = process.env.VUE_APP_FRONT_END_URL;

const headers = [
  {
    text: "Captured On",
    align: "start",
    sortable: true,
    value: "created_on",
  },
  {
    text: "Captured From",
    align: "start",
    sortable: true,
    value: "app",
  },
  {
    text: "Type",
    align: "start",
    sortable: true,
    value: "file_extension",
  },
  {
    text: "Capture",
    align: "start",
    sortable: false,
    value: "actions",
  },
];

export default {
  name: "Captures",
  components: {
    PlainTopBar,
  },
  data() {
    return {
      mdiContentCopy,
      mdiOpenInNew,
      mdiCameraIris,
      headers,
      captures: [],
      FRONT_END_URL,
      tableHeight: 0,
    };
  },
  methods: {
    ...mapMutations(["setMenuState"]),
    hideMenu() {
      this.setMenuState(false);
    },
    async getUserCaptures() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/captures`);
      this.captures = results;
    },
    async copyCaptureLink(item) {
      const videoURL = `${this.FRONT_END_URL}/capture/${item.file_id}`;
      await navigator.clipboard.writeText(videoURL);
    },
    openCaptureLink(item) {
      const videoURL = `${this.FRONT_END_URL}/capture/${item.file_id}`;
      window.open(videoURL, "_blank");
    },
    onResize() {
      const height =
        window.innerHeight -
        (this.$refs.topBar?.clientHeight ?? 80) -
        (this.$refs.title?.clientHeight ?? 40) -
        70;
      this.tableHeight = height;
    },
    formatDateTime(item) {
      const userDateTime = moment(item.created_on).utcOffset(
        moment().utcOffset()
      );
      return userDateTime.format("MMM D, YYYY - h:mm A");
    },
    formatType(item) {
      if (
        item.file_extension === "webm" ||
        item.file_extension === "mp4" ||
        item.file_extension === "mov"
      ) {
        return "video/" + item.file_extension;
      }
      if (
        item.file_extension === "heic" ||
        item.file_extension === "png" ||
        item.file_extension === "jpg"
      ) {
        return "photo/" + item.file_extension;
      }
    },
  },
  beforeMount() {
    this.getUserCaptures();
  },
  mounted() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "hidden";
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
    window.removeEventListener("resize", this.onResize);
  },
  created() {
    this.hideMenu();
  },
};
</script>

<style scoped>
::v-deep .v-data-footer {
  margin-right: 4px !important;
  flex-wrap: nowrap !important;
}

::v-deep .v-data-footer__pagination {
  margin: 0 24px 0 24px !important;
}
</style>
